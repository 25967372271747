<template>
	<div class="register">
		<div class="box-card">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="用户注册" name="first">
					<el-form v-if="activeName == 'first'" :model="registerForm" :rules="registerRules" ref="registerRef"
						label-width="100px" label-position="right" show-message>

						<el-form-item label="经营类型" >
						<el-select v-model="registerForm.activeselect" value-key="label_id" placeholder="请选择" @change="sellectoption">
						    <el-option
						      v-for="item in registerForm.radioData"
						      :key="item.label_id"
						      :label="item.label_name"
						      :value="item">
						    </el-option>
						  </el-select>
						</el-form-item>


						<el-form-item label="用户名" prop="username">
							<el-input v-model="registerForm.username" placeholder="请输入用户名"></el-input>
						</el-form-item>
						<el-form-item label="密码" prop="password">
							<el-input v-model="registerForm.password" placeholder="请输入密码" type="password"></el-input>
						</el-form-item>
						<el-form-item label="确认密码" prop="checkPass">
							<el-input v-model="registerForm.checkPass" placeholder="请输入确认密码" type="password"></el-input>
						</el-form-item>
						<el-form-item label="企业名称" prop="nickname">
							<el-input v-model="registerForm.nickname" placeholder="请输入企业名称"></el-input>
						</el-form-item>
						<el-form-item label="真实姓名" prop="realname">
							<el-input v-model="registerForm.realname" placeholder="请输入真实姓名"></el-input>
						</el-form-item>
						<el-form-item label="验证码" prop="captcha_code">
							<el-input v-model="registerForm.captcha_code" placeholder="请输入验证码" maxlength="4">
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getCode" />
								</template>
							</el-input>
						</el-form-item>
						<el-form-item label="地区选择" prop="address">
							<el-select v-model="registerForm.province_id" placeholder="请选择省"
								@change="changeProvice(registerForm.province_id)">
								<el-option v-for="option in province" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
							<el-select v-model="registerForm.city_id" placeholder="请选择市"
								@change="changeCity(registerForm.city_id)">
								<el-option v-for="option in city" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
							<el-select v-model="registerForm.district_id" placeholder="请选择区/县"
								@change="changeDistrict(registerForm.district_id)">
								<el-option v-for="option in district" :key="option.id" :label="option.name"
									:value="option.id">{{ option.name }}</el-option>
							</el-select>
						</el-form-item>
                        <el-form-item label="上传相应资质" prop="address">
                            <div style="display: flex;flex-wrap: wrap">
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_id_card_A"
                                        :on-remove="handleRemove"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.id_card_A" :src="registerForm.qualifications_images.id_card_A" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传身份证正反面复印件
                                </div>
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_Business_license"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.Business_license" :src="registerForm.qualifications_images.Business_license" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传营业执照
                                </div>
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_Drug_business_license"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.Drug_business_license" :src="registerForm.qualifications_images.Drug_business_license" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传药品经营许可证
                                </div>
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_purchas_attorney"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.purchas_attorney" :src="registerForm.qualifications_images.purchas_attorney" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传采购委托书
                                </div>
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_medical_institution_license"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.medical_institution_license" :src="registerForm.qualifications_images.medical_institution_license" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传医疗机构许可证
                                </div>
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_operation_quality"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.operation_quality" :src="registerForm.qualifications_images.operation_quality" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传药品经营质量管理<br/>规范认证证书
                                </div>
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_change_record"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.change_record" :src="registerForm.qualifications_images.change_record" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传变更记录
                                </div>
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_copy_Page"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.copy_Page" :src="registerForm.qualifications_images.copy_Page" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传副本页
                                </div>
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_two_device_certificate"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.two_device_certificate" :src="registerForm.qualifications_images.two_device_certificate" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传二类医疗器械证
                                </div>
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_food_business_license"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.food_business_license" :src="registerForm.qualifications_images.food_business_license" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传食品经营许可证
                                </div>
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_identityimgquality"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.identityimgquality" :src="registerForm.qualifications_images.identityimgquality" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传质量体系调查表
                                </div>
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_identityimgyear"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.identityimgyear" :src="registerForm.qualifications_images.identityimgyear" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传企业年度报告
                                </div>
                                <div style="margin:0 5px">
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://yddv4.shenglitaiyy.com/api/i/image/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess_identityimgInvoicing"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="registerForm.qualifications_images.identityimgInvoicing" :src="registerForm.qualifications_images.identityimgInvoicing" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    上传开票信息
                                </div>
                            </div>
                        </el-form-item>
						<el-form-item label="" prop="full_address">
							<el-input v-model="registerForm.full_address" :disabled="true" class="ns-len-input"></el-input>
						</el-form-item>
					</el-form>
					<div class="xy" @click="check">
						<div class="xy-wrap">
							<div class="iconfont" :class="ischecked ? 'icon-xuanze-duoxuan' : 'icon-xuanze'"></div>
							<div class="content">
								阅读并同意
								<b @click.stop="getAggrement">《服务协议》</b>
							</div>
						</div>
						<div class="toLogin" @click="toLogin">已有账号，立即登录</div>
					</div>
					<el-button @click="register">立即注册</el-button>
				</el-tab-pane>

				<!-- <el-tab-pane label="手机动态码注册" name="second" v-if="registerConfig.register.indexOf('mobile') != -1">
					<el-form v-if="activeName == 'second'" :model="registerForm" :rules="mobileRules" ref="mobileRuleForm">
						<el-form-item prop="mobile">
							<el-input v-model="registerForm.mobile" placeholder="请输入手机号">
								<template slot="prepend">
									<i class="iconfont icon-shouji-copy"></i>
								</template>
							</el-input>
						</el-form-item>

						<el-form-item prop="vercode">
							<el-input v-model="registerForm.vercode" autocomplete="off" placeholder="请输入验证码" maxlength="4">
								<template slot="prepend">
									<i class="iconfont icon-yanzhengma"></i>
								</template>
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getCode" />
								</template>
							</el-input>
						</el-form-item>

						<el-form-item prop="dynacode">
							<el-input v-model="registerForm.dynacode" maxlength="4" placeholder="请输入短信动态码">
								<template slot="prepend">
									<i class="iconfont icon-dongtaima"></i>
								</template>
								<template slot="append">
									<div class="dynacode" :class="dynacodeData.seconds == 120 ? 'ns-text-color' : 'ns-text-color-gray'" @click="sendMobileCode('mobileRuleForm')">
										{{ dynacodeData.codeText }}
									</div>
								</template>
							</el-input>
						</el-form-item>


					</el-form>
					<div class="xy" @click="check">
						<div class="xy-wrap">
							<div class="iconfont" :class="ischecked ? 'icon-xuanze-duoxuan' : 'icon-xuanze'"></div>
							<div class="content">
								阅读并同意
								<b @click.stop="getAggrement">《服务协议》</b>
							</div>
						</div>
						<div class="toLogin" @click="toLogin">已有账号，立即登录</div>
					</div>

					<el-button @click="registerMobile">立即注册</el-button>
				</el-tab-pane> -->
			</el-tabs>
			<el-dialog :title="agreement.title" :visible.sync="aggrementVisible" width="60%"
				:before-close="aggrementClose" :lock-scroll="false" center>
				<div v-html="agreement.content" class="xyContent"></div>
			</el-dialog>
		</div>
		<!-- 浮层区 -->
		<div class="floatLayer-wrap" v-show="is_show && reward" :style="{width:bgWidth,height:bgHeight}">
			<div class="reward-wrap">
				<img :src="$util.img('upload/uniapp/register_reward_img.png')" mode="widthFix"
					class="bg-img-head"></img>
				<img :src="$util.img('upload/uniapp/register_reward_money.png')" mode="widthFix"
					class="bg-img-money"></img>
				<img :src="$util.img('upload/uniapp/register_reward_head.png')" mode="widthFix" class="bg-img"></img>
				<div class="wrap">
					<div>
						<div class="reward-content">
							<div class="reward-item" v-if="reward && reward.point > 0">
								<div class="head">积分奖励</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.point }}</span>
											<span class="type">积分</span>
										</div>
										<div class="desc">用于下单时抵现或兑换商品等</div>
									</div>
									<div class="tip" @click="closeRewardPopup('point')">立即查看</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.growth > 0">
								<div class="head">成长值</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.growth }}</span>
											<span class="type">成长值</span>
										</div>
										<div class="desc">用于提升会员等级</div>
									</div>
									<div class="tip" @click="closeRewardPopup('growth')">立即查看</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.balance > 0">
								<div class="head">红包奖励</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.balance }}</span>
											<span class="type">元</span>
										</div>
										<div class="desc">不可提现下单时可用</div>
									</div>
									<div class="tip" @click="closeRewardPopup('balance')">立即查看</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.coupon_list.length > 0">
								<div class="head">优惠券奖励</div>
								<div class="content" v-for="(item, index) in reward.coupon_list" :key="index">
									<div class="info">
										<div>
											<span class="num coupon-name">{{ item.coupon_name }}</span>
										</div>
										<div class="desc" v-if="item.at_least > 0">
											满{{ item.at_least }}{{ item.type == 'discount' ? '打' + item.discount + '折' : '减' + item.money }}
										</div>
										<div class="desc" v-else>
											无门槛，{{ item.type == 'discount' ? '打' + item.discount + '折' : '减' + item.money }}
										</div>
									</div>
									<div class="tip" @click="closeRewardPopup('coupon')">立即查看</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="close-btn" @click="closeRewardPopup()">
					<i class="iconfont icon-guanbi"></i>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import {
		getRegisiterAggrement,
		registerConfig,
		registerMobileCode,
		getRegisterReward,
		register,
		registerlabellist
	} from '@/api/auth/register';
	import {
		captcha
	} from '@/api/website';
	import {
		getArea
	} from "@/api/address"
	export default {
		name: 'register',
		components: {},
		data() {
			var checkPassValidata = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.registerForm.password) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			let self = this;
			var passwordValidata = function(rule, value, callback) {
				let regConfig = self.registerConfig;
				if (!value) {
					return callback(new Error('请输入密码'));
				} else {
					if (regConfig.pwd_len > 0) {
						if (value.length < regConfig.pwd_len) {
							return callback(new Error('密码长度不能小于' + regConfig.pwd_len + '位'));
						} else {
							callback();
						}
					} else {
						if (regConfig.pwd_complexity != '') {
							let passwordErrorMsg = '密码需包含',
								reg = '';
							if (regConfig.pwd_complexity.indexOf('number') != -1) {
								reg += '(?=.*?[0-9])';
								passwordErrorMsg += '数字';
							} else if (regConfig.pwd_complexity.indexOf('letter') != -1) {
								reg += '(?=.*?[a-z])';
								passwordErrorMsg += '、小写字母';
							} else if (regConfig.pwd_complexity.indexOf('upper_case') != -1) {
								reg += '(?=.*?[A-Z])';
								passwordErrorMsg += '、大写字母';
							} else if (regConfig.pwd_complexity.indexOf('symbol') != -1) {
								reg += '(?=.*?[#?!@$%^&*-])';
								passwordErrorMsg += '、特殊字符';
							} else {
								reg += '';
								passwordErrorMsg += '';
							}

							if (reg.test(value)) {
								return callback(new Error(passwordErrorMsg));
							} else {
								callback();
							}
						}
					}
				}
			};
			var isMobile = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("手机号不能为空"))
				} else {
					const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

					if (reg.test(value)) {
						callback()
					} else {
						callback(new Error("请输入正确的手机号"))
					}
				}
			};
			return {
                fileList: [],
                imageUrl:'',
				registerForm: {
                    qualifications_images:{
                        id_card_A:'', //身份证正反面
                        Business_license:'', //营业执照
                        Drug_business_license:'', //药品经营许可证
                        purchas_attorney:'', //采购委托书
                        medical_institution_license:'', //医疗机构许可证
                        operation_quality:'', //药品经营质量管理规范认证证书
                        change_record:'', //变更记录
                        copy_Page:'',//副本页,
                        two_device_certificate:'',//二类医疗器械证
                        food_business_license:'',//食品经营许可证
                        identityimgquality:'',//质量体系调查表
                        identityimgyear:'',//企业年度报告
                        identityimgInvoicing:'',//开票信息
                    },
					username: '',
					password: '',
					checkPass: '',
					mobile: '',
					vercode: '',
					dynacode: '',
					key: '',
					nickname: '',
					realname: '',
					tel: '',
					captcha_code: '',
					province_id: '',
					city_id: '',
					district_id: '',
					full_address: '',
					member_label_name: '',
					member_label: '',
					radioData: [],
					activeselect:'',
				},
				registerRules: {
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						validator: passwordValidata,
						trigger: 'blur'
					}],
					checkPass: [{
						required: true,
						validator: checkPassValidata,
						trigger: 'blur'
					}],
					captcha_code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}],
					nickname: [{
						required: true,
						message: '请输入企业名称',
						trigger: 'blur'
					}],
					realname: [{
						required: true,
						message: '请输入真实姓名',
						trigger: 'blur'
					}],
					member_label: [{
						required: true,
						message: '请选择经营类型',
						trigger: 'blur'
					}],
					full_address: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}]
				},
				mobileRules: {
					mobile: [{
						required: true,
						validator: isMobile,
						trigger: "blur"
					}],
					vercode: [{
						required: true,
						message: "请输入验证码",
						trigger: "blur"
					}],
					dynacode: [{
						required: true,
						message: "请输入短信动态码",
						trigger: "blur"
					}]
				},
				dynacodeData: {
					seconds: 120,
					timer: null,
					codeText: "获取动态码",
					isSend: false
				}, // 动态码
				ischecked: false,
				agreement: '',
				aggrementVisible: false,
				captcha: {
					// 验证码
					id: '',
					img: ''
				},
				registerConfig: {
					register: ''
				},
				activeName: "first", // tab切换
				reward: null,
				is_show: false,
				bgWidth: '',
				bgHeight: '',
				province: [],
				city: [],
				district: [],
				pickerValueArray: [],
				multiIndex: [0, 0, 0],
				isInitMultiArray: false,
				// 是否加载完默认地区
				isLoadDefaultAreas: true,
				loading: true,
				yes: true
			};
		},
		created() {
			this.getCode();
			this.regisiterAggrement();
			this.getRegisterConfig();
			this.getRegisterReward();
			this.bgWidth = document.documentElement.clientWidth + "px";
			this.bgHeight = document.documentElement.clientHeight + "px";
			this.getDefaultAreas(0, {
				level: 0
			})
			this.jingy();
		},
		computed: {
			pickedArr() {
				// 进行初始化
				if (this.isInitMultiArray) {
					return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
				}
				return [this.pickerValueArray[0], this.city, this.district]
			}
		},
		methods: {
            handleRemove(file) {
                console.log(file, 'handleRemove');
            },
            handleAvatarSuccess_id_card_A(res, file) {
                console.log(res,'res1111111111111')
                this.registerForm.qualifications_images.id_card_A =res.data.pic_path;
            },
            handleAvatarSuccess_Business_license(res, file) {
                this.registerForm.qualifications_images.Business_license = res.data.pic_path;
            },
            handleAvatarSuccess_Drug_business_license(res, file) {
                this.registerForm.qualifications_images.Drug_business_license = res.data.pic_path;
            },
            handleAvatarSuccess_purchas_attorney(res, file) {
                this.registerForm.qualifications_images.purchas_attorney = res.data.pic_path;
            },
            handleAvatarSuccess_medical_institution_license(res, file) {
                this.registerForm.qualifications_images.medical_institution_license = res.data.pic_path;
            },
            handleAvatarSuccess_operation_quality(res, file) {
                this.registerForm.qualifications_images.operation_quality = res.data.pic_path;
            },
            handleAvatarSuccess_change_record(res, file) {
                this.registerForm.qualifications_images.change_record = res.data.pic_path;
            },
            handleAvatarSuccess_copy_Page(res, file) {
                this.registerForm.qualifications_images.copy_Page = res.data.pic_path;
            },
            handleAvatarSuccess_two_device_certificate(res, file) {
                this.registerForm.qualifications_images.two_device_certificate = res.data.pic_path;
            },
            handleAvatarSuccess_food_business_license(res, file) {
                this.registerForm.qualifications_images.food_business_license = res.data.pic_path;
            },
            handleAvatarSuccess_identityimgquality(res, file) {
                this.registerForm.qualifications_images.identityimgquality = res.data.pic_path;
            },
            handleAvatarSuccess_identityimgyear(res, file) {
                this.registerForm.qualifications_images.identityimgyear = res.data.pic_path;
            },
            handleAvatarSuccess_identityimgInvoicing(res, file) {
                this.registerForm.qualifications_images.identityimgInvoicing = res.data.pic_path;
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
			closeRewardPopup(type) {
				this.is_show = false;

				switch (type) {
					case 'point':
						this.$router.push('/member/my_point');
						break;
					case 'balance':
						this.$router.push('/member/account');
						break;
					case 'growth':
						this.$router.push('/member/index');
						break;
					case 'coupon':
						this.$router.push('/member/my_coupon');
						break;
					default:
						this.$router.push('/member/index');
						this.is_show = false;
				}
			},
			getRegisterReward() {
				getRegisterReward()
					.then(res => {
						if (res.code >= 0) {
							let data = res.data;
							if (data.is_use == 1 && (data.value.point > 0 || data.value.balance > 0 || data.value
									.growth > 0 || data.value.coupon_list.length > 0)) {
								this.reward = data.value;
							}
						}
						console.log(res, 'getRegisterReward')
					})
					.catch(err => {
						console.log(err, 'getRegisterReward')
					})
			},
			sendMobileCode(formName) {
				if (this.dynacodeData.seconds != 120) return
				this.$refs[formName].clearValidate("dynacode")

				this.$refs[formName].validateField("mobile", valid => {
					if (valid) {
						return false
					}
				})
				this.$refs[formName].validateField("vercode", valid => {
					if (!valid) {
						registerMobileCode({
								mobile: this.registerForm.mobile,
								captcha_id: this.captcha.id,
								captcha_code: this.registerForm.vercode
							})
							.then(res => {
								if (res.code >= 0) {
									this.registerForm.key = res.data.key
									if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer == null) {
										this.dynacodeData.timer = setInterval(() => {
											this.dynacodeData.seconds--
											this.dynacodeData.codeText = this.dynacodeData.seconds +
												"s后可重新获取"
										}, 1000)
									}
								}
							})
							.catch(err => {
								this.$message.error(err.message)
							})
					} else {
						return false
					}
				})
			},
			handleClick(tab, event) {
				if (this.activeName == "first") {
					this.loginMode = "account"
				} else {
					this.loginMode = "mobile"
				}
			},
			check() {
				this.ischecked = !this.ischecked;
			},
			toLogin() {
				this.$router.push('/login');
			},
			//  获取注册配置
			getRegisterConfig() {
				registerConfig()
					.then(res => {
						if (res.code >= 0) {
							this.registerConfig = res.data.value;
							if (this.registerConfig.register == '') {
								this.$message({
									message: '平台未启用注册',
									type: 'warning',
									duration: 2000,
									onClose: () => {
										this.$router.push('/');
									}
								});
							}
						}
					})
					.catch(err => {
						console.log(err.message)
					});
			},
			//经营类型
			jingy() {
				registerlabellist().then(res => {
					console.log(res.data)
					const {
						count,
						list
					} = res.data
					this.registerForm.radioData = list

				})
			},
			sellectoption(value){
				//判断默认选择的数据
				this.member_label = value.label_id;
				this.registerForm.member_label_name = value.label_name;
			},
			// 账号密码注册
			register() {
				this.$refs.registerRef.validate(valid => {
					if (valid) {
						if (!this.ischecked) {
							return this.$message({
								message: '请先阅读协议并勾选',
								type: 'warning'
							});
						}
						var data = {
							member_label: this.member_label, //企业类型
							username: this.registerForm.username.trim(), //用户名
							password: this.registerForm.password, //密码
							realname: this.registerForm.realname, //真实姓名
							nickname: this.registerForm.nickname, //
							captcha_id: this.captcha_id,
							captcha_code: this.registerForm.captcha_code,
							province_id: this.registerForm.province_id, //省
							city_id: this.registerForm.city_id, //市
							district_id: this.registerForm.district_id, //区
							full_address: this.registerForm.full_address, //详细地址
							member_label_name: this.registerForm.member_label_name,
                            qualifications_images:JSON.stringify(this.registerForm.qualifications_images)
						};

						if (this.captcha.id != '') {
							data.captcha_id = this.captcha.id;
							data.captcha_code = this.registerForm.captcha_code;
						}
						this.$store.dispatch('member/register_token', data)
							.then(res => {

								if (res.code >= 0) {
									if (this.reward) {
										this.is_show = true;
									} else {
										this.$router.push('/member/index');
									}
								}
							})
							.catch(err => {
								this.$message.error(err.message);
								this.getCode();
							});
					} else {
						return false;
					}
				});
			},
			// 手机号注册
			registerMobile() {
				this.$refs.mobileRuleForm.validate(valid => {
					if (valid) {
						if (!this.ischecked) {
							return this.$message({
								message: '请先阅读协议并勾选',
								type: 'warning'
							});
						}
						var data = {
							mobile: this.registerForm.mobile,
							key: this.registerForm.key,
							code: this.registerForm.dynacode
						};
						if (this.captcha.id != '') {
							data.captcha_id = this.captcha.id;
							data.captcha_code = this.registerForm.code;
						}
						this.$store
							.dispatch('member/registerMobile_token', data)
							.then(res => {
								if (res.code >= 0) {
									if (this.reward) {
										this.is_show = true;
									} else {
										this.$router.push('/member/index');
									}
								}
							})
							.catch(err => {
								this.$message.error(err.message);
								this.getCode();
							});
					} else {
						return false;
					}
				});
			},
			aggrementClose() {
				this.aggrementVisible = false;
			},
			// 获取协议
			regisiterAggrement() {
				getRegisiterAggrement()
					.then(res => {
						if (res.code >= 0) {
							this.agreement = res.data;
						}
					})
					.catch(err => {
						console.log(err.message)
					});
			},
			getAggrement() {
				this.aggrementVisible = true;
			},
			// 获取验证码
			getCode() {
				captcha({
						captcha_id: 'this.captcha.id'
					})
					.then(res => {
						if (res.code >= 0) {
							this.captcha = res.data;
							this.captcha.img = this.captcha.img.replace(/\r\n/g, '');
						}
					})
					.catch(err => {
						this.$message.error(err.message);
					});
			},
			/**
			 * 改变省
			 */
			changeProvice(id) {
				this.getAreas(id, data => (this.city = data))
				let obj = {}
				obj = this.province.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.registerForm.city_id = ""
				this.registerForm.district_id = ""
				this.registerForm.full_address = obj.name // 设置选中的地址
			},
			/**
			 * 改变市
			 */
			changeCity(id) {
				this.getAreas(id, data => (this.district = data))
				let obj = {}
				obj = this.city.find(item => {
			  //这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.registerForm.district_id = ""
				 this.registerForm.full_address = this.registerForm.full_address + "-" + obj.name
			},
			/**
			 * 改变区
			 */
			changeDistrict(id) {
				let obj = {}
				obj = this.district.find(item => {
					//这里的province就是上面遍历的数据源
			  return item.id === id //筛选出匹配数据
				})
				 this.registerForm.full_address = this.registerForm.full_address + "-" + obj.name
			},

			// 异步获取地区
			getAreas(pid, callback) {
				getArea({
						pid: pid
					}).then(res => {

						if (res.code == 0) {
							var data = []
							res.data.forEach((item, index) => {
								data.push(item)
							})
							if (callback) callback(data)
						}
					})
					.catch(err => {})
			},
			/**
			 * 获取省市区列表
			 */
			getDefaultAreas(pid, obj) {
				getArea({
						pid: pid
					})
					.then(res => {
						if (res.code == 0) {
							var data = []
							var selected = undefined
			  		res.data.forEach((item, index) => {
								if (obj != undefined) {
									if (obj.level == 0 && obj.province_id != undefined) {
										selected = obj.province_id
									} else if (obj.level == 1 && obj.city_id != undefined) {
										selected = obj.city_id
									} else if (obj.level == 2 && obj.district_id != undefined) {
										selected = obj.district_id
									}
								}

								if (selected == undefined && index == 0) {
									selected = item.id
								}
								data.push(item)
							})

							this.pickerValueArray[obj.level] = data
							if (obj.level + 1 < 3) {
								obj.level++
								this.getDefaultAreas(selected, obj)
							} else {
			  			this.isInitMultiArray = true
								this.isLoadDefaultAreas = true
							}

							this.province = this.pickerValueArray[0]
						}
						this.loading = false
					})
					.catch(err => {
						this.loading = false
					})
			},
			/**
			 * 渲染默认值
			 */
			handleDefaultRegions() {
				var time = setInterval(() => {
					if (!this.isLoadDefaultAreas) return
					this.isInitMultiArray = false

					for (let i = 0; i < this.defaultRegions.length; i++) {
						for (let j = 0; j < this.pickerValueArray[i].length; j++) {
							this.province = this.pickerValueArray[0]

							// 匹配省
							if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
								// 设置选中省
								this.$set(this.multiIndex, i, j)
								// 查询市
								this.getAreas(this.pickerValueArray[i][j].id, data => {
									this.city = data

									for (let k = 0; k < this.city.length; k++) {
										if (this.defaultRegions[1] == this.city[k].id) {
											// 设置选中市
											this.$set(this.multiIndex, 1, k)

											// 查询区县
											this.getAreas(this.city[k].id, data => {
												this.district = data

												// 设置选中区县
												for (let u = 0; u < this.district.length; u++) {
													if (this.defaultRegions[2] == this.district[u]
														.id) {
														this.$set(this.multiIndex, 2, u)
														this.handleValueChange({
															detail: {
																value: [j, k, u]
															}
														})
														break
													}
												}
											})

											break
										}
									}
								})
							}
						}
					}
					if (this.isLoadDefaultAreas) clearInterval(time)
				}, 100)
			},
			handleValueChange(e) {
				// 结构赋值
				let [index0, index1, index2] = e.detail.value
				let [arr0, arr1, arr2] = this.pickedArr
				let address = [arr0[index0], arr1[index1], arr2[index2]]

				this.formData.full_address = ""
				for (let i = 0; i < address.length; i++) {
					if (this.formData.full_address) {
						this.formData.full_address = this.formData.full_address + "-" + address[i].name
					} else {
						this.formData.full_address = this.formData.full_address + address[i].name
					}
				}
			},
		}
	};
</script>
<style lang="scss" scoped>

    //aaa
    .avatar-uploader .el-upload {
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
        border: 1px dashed #d9d9d9;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    //aaa

	.register {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 20px 0;
	}

	.box-card {
		width: 1000px;
		margin: 0 auto;
		display: flex;
		background-color: #ffffff;
		padding: 0 30px 30px 30px;
		flex-direction: column;
		padding-bottom: 256px;

		.register-title {
			border-bottom: 1px solid #f1f1f1;
			text-align: left;
			margin-bottom: 20px;
			font-size: 16px;
			color: $base-color;
			padding: 10px 0;
		}

		.register-account {
			width: 100%;
			text-align: center;
		}

		.code {
			width: 80%;
			text-align: left;
		}

		.el-form {
			margin: 0 30px;

			.captcha {
				vertical-align: top;
				max-width: inherit;
				max-height: 38px;
				line-height: 38px;
				cursor: pointer;
			}
		}

		.xyContent {
			height: 600px;
			overflow-y: scroll;
		}

		.xy {
			margin-left: 110px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-align: left;
			margin-right: 30px;

			.toLogin {
				cursor: pointer;
			}

			.xy-wrap {
				display: flex;
				align-items: center;
				font-size: $ns-font-size-base;
				cursor: pointer;

				.iconfont {
					display: flex;
					align-content: center;
				}

				.content {
					margin-left: 3px;

					b {
						color: $base-color;
					}
				}
			}

			.icon-xuanze-duoxuan {
				color: $base-color;
			}
		}

		.el-button {
			margin: 20px 0 0 25px;
			background-color: $base-color;
			color: #ffffff;
			width: calc(100% - 60px);
		}

		.el-cascader {
			widht: 100%;
		}
	}

	.floatLayer-wrap {
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0, .5);
		z-index: 999;
		position: absolute;

		.reward-wrap {
			width: 400px;
			height: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.bg-img {
				width: 100%;
				will-change: transform;
			}

			.bg-img-head {
				position: absolute;
				top: -90px;
				width: 100%;

			}

			.bg-img-money {
				position: absolute;
				width: 100%;
				left: -20px;
				top: 80px;
				z-index: 10;
			}

			.wrap {
				width: calc(100% - 1px);
				height: 100%;
				background-color: #ef3030;
				margin-top: -40px;
				padding-bottom: 30px;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;

				&>div {
					position: relative;
				}
			}

			.reward-content {
				margin: 0 25px 0 25px;
			}

			.reward-item {
				.head {
					color: #fff;
					text-align: center;
					line-height: 1;
					margin: 10px 0;
				}

				.content {
					display: flex;
					padding: 8px 13px;
					background: #fff;
					border-radius: 5px;
					margin-bottom: 5px;

					.info {
						flex: 1;
					}

					.tip {
						color: #ff222d;
						padding: 5px 0 5px 15px;
						width: 70px;
						line-height: 1.5;
						letter-spacing: 1px;
						border-left: 1px dashed #e5e5e5;
						height: 40px;
						line-height: 40px;
					}

					.num {
						font-size: 26px;
						color: #ff222d;
						font-weight: bolder;
						line-height: 1;
					}

					.coupon-name {
						font-size: 19px;
					}

					.type {
						font-size: $ns-font-size-base;
						margin-left: 5px;
						line-height: 1;
					}

					.desc {
						margin-top: 4px;
						color: $base-color;
						font-size: $ns-font-size-base;
						line-height: 1;
					}
				}
			}

			.btn {
				position: absolute;
				width: calc(100% - 50px);
				bottom: 20px;
				left: 25px;

				.btn-img {
					width: 100%;
				}
			}
		}
	}

	.close-btn {
		text-align: center;
		margin-top: 20px;

		.iconfont {
			color: #fff;
			font-size: 20px;
		}
	}

	.clear {
		content: '';
		display: block;
		clear: both;
	}

	.el-select {
		margin-right: 10px;

    }

</style>
